.headerLogoImage .desktop_logo {
  width: 200px;
  max-width: 100%;
  display: block;
}
.headerLogoImage .mobile_logo {
  width: 45px;
  max-width: 100%;
  display: none;
}

@media only screen and (max-width: 768px) {
  .headerLogoImage .desktop_logo {
    display: none;
  }
  .headerLogoImage .mobile_logo {
    display: block;
  }
}

.cursorPointer {
  cursor: pointer !important;
}

.connect-wal-green {
  color: green !important;
  background-image: linear-gradient(90deg, #4e10ff 0%, #ab17df 100%) !important;
  border-radius: 30px !important;
  box-shadow: 2px 2px 20px 0 #ab17df !important;
}
.connect-wal-red {
  text-align: center;
  /* color: #fff !important; */
  background: #ff343f !important;
  border-radius: 30px;
  box-shadow: 2px 2px 20px 0 red;
}

/* For responsive css */
