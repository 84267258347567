/* div[class*="nft__item_wrap"] {
  height: 316px !important;
} */
.buttonHover btn:hover {
  color: red !important;
  background-color: none !important;
}

.greyscheme .btn:hover::before {
  background-image: none;
  color: blue !important;
}

.red {
  color: red !important;
}
.purple {
  color: #ab17df !important;
}
.green {
  color: green !important;
}
.blue {
  color: #4e10ff !important;
}
.white2 {
  color: white !important;
}

.white1 {
  color: white !important;
  font-weight: bold;
  /* background-color: none !important; */
  /* background-image: none; */
}

.buttonHover p {
  color: blue !important;
  background-color: gold !important;
}

.buttonHover p:hover {
  color: red !important;
}
.modal-header button {
  background: none !important;
  border: none;
  font-size: 30px;
}
.image_arrange {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 20px;
}
.image_arrange .image {
  width: 130px !important;
  height: 80px !important;
  margin: 0 10px;
  cursor: pointer;
}
.image_arrange1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 20px;
}
@media screen and (max-width: 768px) {
  .image_arrange1 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.unstacking_img {
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
  grid-row-gap: 0px;
  text-align: center;
}
.unstacking_img .nft_img .pair_text {
  text-align: center;
  padding-top: 10px;
}

@media screen and (max-width: 875px) {
  .modal1-body {
    padding: 0px 15px !important;
    height: 405px !important;
    overflow: auto;
  }
  .unstacking_img {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-row-gap: 30px;
  }
  .unstacking_img .nft_img {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    position: relative;
  }
  .unstacking_img .nft_img .pair_text {
    position: absolute;
    bottom: -47px;
    width: 100%;
  }
}

.image_arrange1 .image {
  /* width: 170px !important; */
  height: 170px !important;
  /* margin: 0 5px !important;
  padding: 0px !important; */
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  .image_arrange1 {
    grid-template-columns: repeat(3, 1fr);
  }
  .image_arrange1 .image {
    height: 130px !important;
  }
}
@media screen and (max-width: 420px) {
  .image_arrange1 {
    grid-template-columns: repeat(2, 1fr);
  }
  .image_arrange1 .image {
    height: 120px !important;
  }
}
.modal-body {
  min-height: 20px;
}
.modal-body h6 {
  color: white;
}
.modal-content {
  border: none;
  background-color: #212428 !important;
  border-radius: 2px !important;
}
.modal-title.h4 {
  color: white !important;
}
button span {
  font-size: 30px !important;
  color: white !important;
}
.modal-header {
  border-bottom: none !important;
}
.modal-footer {
  margin: 10px 0;
  display: flex !important;
  flex-wrap: nowrap !important;
  border-top: none !important;
}
.modal-footer button {
  max-width: 100%;
  background-image: linear-gradient(90deg, #4e10ff 0%, #ab17df 100%) !important;
  border-radius: 30px;
  width: 500px;
  margin: auto;
  border: none;
  font-size: 14px;
  color: white !important;
  outline: none !important;
}
.modal-footer button:focus {
  box-shadow: none !important;
}
.modal-footer button:hover {
  box-shadow: 2px 2px 20px 0px #4e10ff !important;
}

.buttonHoverModal {
  background-color: #f2f2f2;
  border: 1px solid #d2d2d2;
  color: black;
  padding: 10px 20px;
}
.cancelBtn {
  margin-right: 10px !important;
}
.continueBtn {
  margin-left: 10px !important;
}
.buttonHoverModal::before {
  background-image: none;
}

.unstack_row .figure img {
  width: 100%;
  height: 180px;
}
@media screen and (max-width: 768px) {
  .unstack_row {
    text-align: center;
  }
  .figure img {
    width: 100% !important;
    height: 200px !important;
  }
}
@media screen and (max-width: 386px) {
  .unstack_row {
    text-align: center;
  }
  .figure img {
    width: 100% !important;
    height: 130px !important;
  }
}
@media screen and (max-width: 525px) {
  .unstack_row {
    text-align: center;
  }
  .figure img {
    width: 100% !important;
    height: 150px !important;
  }
}
@media screen and (max-width: 320px) {
  .unstack_row {
    text-align: center;
  }
  .figure img {
    width: 100% !important;
    height: 100px !important;
  }
}
.unstack_row .figure {
  width: 100%;
}
.figImageunstake {
  width: 220px !important;
  height: 210px !important;
}

@media screen and (max-width: 1399px) {
  .figImageunstake {
    width: 220px !important;
    height: 170px !important;
  }
  /* .figure img {
    width: 220px !important;
    height: 170px !important;
  } */
}
@media screen and (max-width: 1199px) {
  .figImageunstake {
    width: 220px !important;
    height: 130px !important;
  }
}

.modal1-body {
  padding: 0px 15px !important;
  max-height: 420px;
  min-height: 200px;
  overflow: auto;
}
@media screen and (max-width: 768px) {
  .modal1-body {
    max-height: 200px !important;
  }
}
.modal1-body::-webkit-scrollbar {
  width: 4px;
}
/* Track */
.modal1-body::-webkit-scrollbar-track {
  background: #787878;
  border-radius: 50px;
}

/* Handle */
.modal1-body::-webkit-scrollbar-thumb {
  background: #505051;
  border-radius: 50px;
}
@media screen and (min-width: 600px) {
  .modal1 .modal-dialog {
    max-width: 60% !important;
  }
}
@media screen and (max-width: 1280px) {
  .modal1 .modal-dialog {
    max-width: 60% !important;
  }
}
@media screen and (max-width: 1170px) {
  .modal1 .modal-dialog {
    max-width: 70% !important;
  }
}
@media screen and (max-width: 991px) {
  .modal1 .modal-dialog {
    max-width: 80% !important;
  }
}
@media screen and (max-width: 576px) {
  .modal1 .modal-dialog {
    margin: auto;
    margin-top: 20px;
  }
}

/* .stakeButtonClass::before{
  content: "";
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  position: absolute !important;
  background-image: linear-gradient(to left, #FF343F, #FC202C) !important;
  opacity: 0 !important;
  z-index: 1 !important;
  transition: all 0.3s ease !important;
} */

.stakeButtonClass {
  background-image: linear-gradient(90deg, #4e10ff 0%, #ab17df 100%) !important;
  border: 2px solid #4e10ff !important;
}
.stakeButtonClass:focus {
  box-shadow: none;
  background-color: none;
}
.stakeButtonClass:hover {
  /* opacity: 0 !important; */
  z-index: 1 !important;
  box-shadow: 2px 2px 20px 0px #4e10ff !important;
  transition: all 0.3s ease !important;
  /* background-image: linear-gradient(to left, #ab23bd, #425b91) !important; */
}

.cursorClass {
  cursor: pointer !important;
}

.selectMintCSS {
  border-radius: 5px;
  background: #212529 !important;
  color: #333 !important;
}
